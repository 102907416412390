// Enter all your detials in this file
// Logo images
import logogradient from "./assets/logo.svg";
import logo from "./assets/logo2.svg";
// Profile Image
import profile from "./assets/profile.jpg";
// Tech stack images
import html from "./assets/techstack/html.png";
import css from "./assets/techstack/css.png";
import sass from "./assets/techstack/sass.png";
import js from "./assets/techstack/js.png";
import react from "./assets/techstack/react.png";
import redux from "./assets/techstack/redux.png";
import tailwind from "./assets/techstack/tailwind.png";
import bootstrap from "./assets/techstack/bootstrap.png";
import vscode from "./assets/techstack/vscode.png";
import github from "./assets/techstack/github.png";
import git from "./assets/techstack/git.png";
import npm from "./assets/techstack/npm.png";
import postman from "./assets/techstack/postman.png";
import figma from "./assets/techstack/figma.png";
import bubbleio from "./assets/techstack/bubbleio1.png";
import bubbleio1 from "./assets/techstack/bubbleio.png";
import bubbleio2 from "./assets/techstack/bubbleio_icon.png";
import digitalocean from "./assets/techstack/digitalocean.png";
import laravel from "./assets/techstack/laravel-logo.png";
import php from "./assets/techstack/php2.png";
import wordpress from "./assets/techstack/wp-logo.png";

// Porject Images
import projectImage1 from "./assets/projects/lightdashboard.jpg";
import projectImage2 from "./assets/projects/nftmarketplace.jpg";
import projectImage3 from "./assets/projects/simple_user_management.jpg";
import projectImage4 from "./assets/projects/mairmair.PNG";
import projectImage5 from "./assets/projects/bestwatch.PNG";
import projectImage51 from "./assets/projects/robofi1.png"
import projectImage6 from "./assets/projects/finapac.PNG";
import projectImage7 from "./assets/projects/snapinnovation.PNG";
import projectImage8 from "./assets/projects/gamesplatform.PNG";
import projectImage9 from "./assets/projects/thbss.PNG";
import projectImage10 from "./assets/projects/scholarshipguidesg.PNG";
import projectImage11 from "./assets/projects/hardcopynew.png";
import projectImage14 from "./assets/projects/swotai.png";
import projectImage15 from "./assets/projects/smartnew88.png";
import projectImage16 from "./assets/projects/nhai.png";
import projectImage17 from "./assets/projects/golf.png";
import projectImage18 from "./assets/projects/property.png";
import projectImage19 from "./assets/projects/uplinked.png";
import projectImage20 from "./assets/projects/trucking88.png";


// Logos
export const logos = {
  logogradient: logogradient,
  logo: logo,
};

// Enter your Personal Details here
export const personalDetails = {
  name: "Billy Christi",
  tagline: "I build things for web",
  img: profile,
  about: `I am a certified Bubble.io developer with over 5 years of professional experience in software engineering. I have successfully developed and delivered 10+ Bubble.io projects for clients across multiple countries. My expertise extends beyond Bubble.io, including technologies like PHP, JavaScript, React, Laravel, and more, enabling me to build robust, scalable web applications tailored to diverse business needs.`,
};

// Enter your Social Media URLs here
export const socialMediaUrl = {
  linkdein: "https://www.linkedin.com/in/billychristihartanto/",
  github: "https://github.com/billycho",
};

// Enter your Work Experience here
export const workDetails = [
  {
    Position: "Senior Bubble.io Developer",
    Company: `NerdHeadz - Gold Tier Bubble.io Agency`,
    Location: "United States",
    Type: "Full Time",
    Duration: "July 2023 - Present",
    Link: "https://www.nerdheadz.com/"
  },
  {
    Position: "Software Engineer",
    Company: `CSG International`,
    Location: "United States",
    Type: "Full Time",
    Duration: "March 2022 - June 2023",
    Link: "https://www.csgi.com/"
  },
  {
    Position: "Software Engineer",
    Company: `Snap Innovations Pte Ltd`,
    Location: "Indonesia",
    Type: "Full Time",
    Duration: "May 2020 - Feb 2022",
    Link: "https://snapinnovations.com/"
  },
  {
    Position: "Mobile Developer",
    Company: `Astra Graphia Information Technology`,
    Location: "Jakarta, Indonesia",
    Type: "Full Time",
    Duration: "Oct 2018 - Sep 2019",
    Link: "https://www.ag-it.com/"
  },
  {
    Position: "Software Engineer",
    Company: `Mitrais`,
    Location: "Jakarta, Indonesia",
    Type: "Full Time",
    Duration: "Aug 2017 - Sep 2018",
    Link: "https://mitrai.com/"
  },
];

// Enter your Education Details here
export const eduDetails = [
  {
    Position: "Bachelor's degree in Business Information Systems",
    Company: "Petra Christian University",
    Location: "Indonesia",
    Type: "Full Time",
    Duration: "2013 - 2017",
    Link: "https://en.wikipedia.org/wiki/Petra_Christian_University"
  }
];

// Tech Stack and Tools
export const techStackDetails = {
  bubbleio: bubbleio1,
  wordpress: wordpress,
  digitalocean: digitalocean,
  laravel: laravel,
  php: php,
  html: html,
  css: css,
  js: js,
  react: react,
  redux: redux,
  sass: sass,
  tailwind: tailwind,
  bootstrap: bootstrap,
  vscode: vscode,
  postman: postman,
  npm: npm,
  git: git,
  github: github,
  figma: figma,
};

// Enter your Project Details here
export const projectDetails = [
  {
    title: "Smart SETC",
    image: projectImage15,
    description: `Developed a user-friendly website for determining eligibility for up to $32,220 in SETC relief funds. The site features a straightforward, question-based interface to assess qualifications, enhancing accessibility for self-employed individuals. This project showcases my ability to create efficient, user-centric solutions.`,
    techstack: "Bubble.io, Docusign, Javascript, TaxStatus, Stripe, API Connector",
    previewLink: "https://portal.smartsetc.com/",
    githubLink: "https://github.com",
  },
  {
    title: "NerdHeadz AI",
    image: projectImage16,
    description: `Nerdheadz AI – A project estimation platform built with Bubble.io and integrated with ChatGPT AI for fast, accurate estimates in just 5 minutes. Featuring a messaging system to gather user requirements, it generates detailed feature lists and outputs results in a professional PDF format using a custom-built PHP PDF generator.`,
    techstack: "Bubble.io, OpenAI API, Javascript, PHP, API Connector",
    previewLink: "https://estimate.nerdheadz.com/",
    githubLink: "https://github.com",
  },
  
  {
    title: "JLS Golf Club",
    image: projectImage17,
    description: `Effortless online booking and tee time management solution for golf courses in Indonesia. Built with Bubble.io, this user-friendly system enhances the customer experience with intuitive interfaces, ensuring seamless booking and increased player retention.`,
    techstack: "Bubble.io",
    previewLink: "https://jls.golf/",
    githubLink: "https://github.com",
  },
  {
    title: "Property Auction",
    image: projectImage18,
    description: `A specialized platform for searching commercial and residential real estate auctions. Built with Bubble.io, it offers a streamlined experience, displaying only relevant live and online real estate auctions to save users time and simplify property discovery.`,
    techstack: "Bubble.io, Javascript",
    previewLink: "https://propertyauction.com/",
    githubLink: "https://github.com",
  },
  {
    title: "Hardcopy.io",
    image: projectImage11,
    description: `Centrally store your important documents in the Hardcopy Cloud. No more using ad hoc systems, or even paper-based methods! With the Hardcopy platform you and your team can securely file your docs and search with ease.`,
    techstack: "Bubble.io, PHP, JS, MySQL, HTML/CSS",
    previewLink: "https://hardcopy.io/",
    githubLink: "https://github.com",
  },
  {
    title: "UpLinked",
    image: projectImage19,
    description: `A LinkedIn post scheduling and strategy management platform integrated with Stripe for seamless payments. Built with Bubble.io, it helps users plan, generate, and launch effective LinkedIn strategies with ease.`,
    techstack: "Bubble.io, Linkedin API, OpenAI API, Stripe API, Javascript",
    previewLink: "https://my.uplinked.app/start?login=yes",
    githubLink: "https://github.com",
  },
  {
    title: "Trucking88",
    image: projectImage20,
    description: `Trucking88 - A comprehensive TMS platform built with Bubble.io, tailored for small and medium-sized trucking companies. Designed to streamline operations, optimize routes, and enhance efficiency for smarter, more profitable fleet management.`,
    techstack: "Bubble.io, Javascript",
    previewLink: "https://www.trucking88.com/",
    githubLink: "https://github.com",
  },
 
  {
    title: "Scholarship Guide Singapore",
    image: projectImage10,
    description: `Scholarship Guide Singapore is a comprehensive platform offering detailed information on scholarships available in Singapore. Students can easily apply for various scholarships directly through the website, while advertisers can post their scholarship opportunities to reach a wider audience.`,
    techstack: "PHP, JS, Yii, MySQL, HTML/CSS",
    previewLink: "http://scholarshipguide.com.sg/",
    githubLink: "https://github.com",
  },
  {
    title: "mair-mair.com",
    image: projectImage4,
    description: `Developed an Italian Wine E-Commerce platform that seamlessly connects wine enthusiasts with a curated selection of authentic Italian wines. This project highlights my expertise in e-commerce solutions, user experience design, and the integration of secure payment systems.`,
    techstack: "PHP, MySQL, HTML, CSS, Bootstrap",
    previewLink: "https://mair-mair.com",
    githubLink: "https://github.com",
  },
  {
    title: "Light Dashboard",
    image: projectImage1,
    description: `Light Dashboard is a clean & responsive dashboard using white color palette as the base color, main features include table templates, form templates, graphs, user accounts, profiles, responsive sidebar`,
    techstack: "Bubble.io",
    previewLink: "https://light-dashboard.bubbleapps.io",
    githubLink: "https://github.com",
  },
  {
    title: "Simple User Management",
    image: projectImage3,
    description: `Simple User Management is the most simple user management dashboard for Bubble`,
    techstack: "Bubble.io",
    previewLink: "https://simple-user-management.bubbleapps.io/",
    githubLink: "https://github.com",
  },
  
  {
    title: "NFT Marketplace",
    image: projectImage2,
    description: `NFT Marketplace is a marketplace where you can sell your own NFT and buy NFT
    `,
    techstack: "Bubble.io",
    previewLink: "https://nftpagetemplate.bubbleapps.io/",
    githubLink: "https://github.com",
  },
  {
    title: "Robofi.io",
    image: projectImage51,
    description: `I handled the server setup, WordPress development, custom template customization, and created a custom plugin using PHP for RoboFi, a secure and transparent decentralized finance (DeFi) platform. RoboFi offers DAO-based crypto trading bots through an Initial Bot Offering (IBO).`,
    techstack: "WordPress, Custom Plugin Development, PHP, JavaScript, HTML/CSS",
    previewLink: "https://robofi.io/",
    githubLink: "https://github.com",
  },
  {
    title: "Finapac.sg",
    image: projectImage6,
    description: `I managed the server setup and WordPress development for Finapac Capital, a prominent fund management company registered with the Monetary Authority of Singapore and an esteemed member of the Singapore Fintech Association. My role encompassed ensuring a seamless and secure online presence for the company, aligning with industry standards and regulations.`,
    techstack: "WordPress, HTML/CSS",
    previewLink: "https://finapac.sg/",
    githubLink: "https://github.com",
  },
  {
    title: "SnapInnovations.com",
    image: projectImage7,
    description: `I set up the server and developed the WordPress site for Snap Innovations Pte Ltd, a company specializing in Artificial Intelligence, Blockchain, Financial Trading, and Technology Solutions. The goal was to create a reliable and user-friendly platform to highlight their innovative offerings.`,
    techstack: "WordPress, HTML/CSS",
    previewLink: "https://snapinnovations.com/",
    githubLink: "https://github.com",
  }
];

// Enter your Contact Details here
export const contactDetails = {
  email: "billychartanto@gmail.com",
  phone: "",
};
